import Mdx from 'components/containers/mdx';
import { Heading } from 'components/shared/heading';
import { TerminalHeader } from 'components/shared/terminal-header';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React from 'react';

import styles from './instruction.module.scss';

export const Instruction = () => {
  const {
    fourthStepImage,
    codeData: { edges: CODE_DATA },
  } = useStaticQuery(graphql`
    query {
      fourthStepImage: file(name: { regex: "/unit-testing-for-performance-fourth-step-image/" }) {
        childImageSharp {
          gatsbyImageData(width: 550)
        }
      }
      codeData: allMdx(
        filter: { fileAbsolutePath: { regex: "/data/unit-testing-for-performance-instruction/" } }
        sort: { fields: fileAbsolutePath, order: ASC }
      ) {
        edges {
          node {
            body
          }
        }
      }
    }
  `);

  return (
    <section className={styles.wrapper}>
      <div className={'container'}>
        <div className={'row justify-content-center'}>
          <Heading className={styles.title} tag={'h2'} size={'xs'}>
            5 steps for automated performance testing:
          </Heading>
        </div>
        {/* classic b4 nesting */}
        <div className={`row ${styles.instructionWrapper}`}>
          {/* first step block */}
          <div className={`col-12 ${styles.stepWrapper}`}>
            <div className={'row'}>
              <div className={'col-lg-7 col-12'}>
                <Mdx mode="plain" content={CODE_DATA[0].node.body} className={styles.codeBlockWrapper} />
              </div>
              <div className={'order-lg-last col-lg-5 col-12 order-first justify-content-center d-flex flex-column'}>
                <Heading className={styles.stepTitle} tag={'h1'}>
                  1. Write your load test
                </Heading>
                <p className={styles.stepDescription}>
                  Use the k6 Javascript API to write simple or realistic load test.
                </p>
              </div>
            </div>
          </div>
          {/* second step block */}
          <div className={`col-12 ${styles.stepWrapper}`}>
            <div className={'row'}>
              <div className={'col-lg-7 col-12'}>
                <Mdx mode="plain" content={CODE_DATA[1].node.body} className={styles.codeBlockWrapper} />
              </div>
              <div className={'order-lg-last col-lg-5 col-12 order-first justify-content-center d-flex flex-column'}>
                <Heading className={styles.stepTitle}>2. Configure performance thresholds</Heading>
                <p className={styles.stepDescription}>
                  - 95th percentile response time must be below 1s.
                  <br />- HTTP errors must be less than 1%.
                </p>
              </div>
            </div>
          </div>
          {/* third step block */}
          <div className={`col-12 ${styles.stepWrapper} ${styles.stepWrapperThird}`}>
            <div className={'row'}>
              <div className={'col-lg-7 col-12'}>
                <div className={styles.codeBlockWrapper}>
                  <Mdx mode="plain" content={CODE_DATA[2].node.body} className={styles.codeBlockWrapper} />
                </div>
              </div>
              <div className={'order-lg-last col-lg-5 col-12 order-first justify-content-center d-flex flex-column'}>
                <Heading className={`${styles.stepTitle}`}>3. Run the test and check the pass/fail status</Heading>
                <p className={styles.stepDescription}>
                  Test many load configurations to ensure your performance goals in multiple situations.
                </p>
              </div>
            </div>
          </div>
          {/* fourth step block */}
          <div className={`col-12 ${styles.stepWrapper} ${styles.stepWrapperFourth}`}>
            <div className={'row'}>
              <div className={'col-xl-6 col-lg-7 col-12'}>
                <GatsbyImage
                  image={getImage(fourthStepImage)}
                  transformOptions={{ cropFocus: 'east' }}
                  className={styles.stepImage}
                />
              </div>
              <div
                className={
                  'order-lg-last col-lg-5 offset-xl-1 col-12 order-first justify-content-center d-flex flex-column'
                }
              >
                <Heading className={`${styles.stepTitle}`}>4. Automate your performance tests</Heading>
                <p className={styles.stepDescription}>
                  Run your tests frequently to be confident in your system&apos;s performance.
                </p>
                <p className={styles.stepDescription}>
                  Schedule your test execution and integrate your tests into CI pipelines.
                </p>
              </div>
            </div>
          </div>
          {/* fifth step block */}
          <div className={`col-12 ${styles.stepWrapper} ${styles.stepWrapperFifth}`}>
            <div className={'row'}>
              <div className={'col-xl-6 col-lg-7 col-12'}>
                <div className={styles.testResultsWrapper}>
                  <TerminalHeader theme={'grey'} />
                  <div className={styles.testResults}>
                    <span className={`${styles.testResultsIcon} ${styles.iconFail}`} />
                    <Heading tag={'h3'} size={'md'}>
                      Performance test
                    </Heading>
                    <p className={styles.testResultsStatus}>Checkout API. 2 of 4 thresholds failed.</p>
                  </div>
                  <div className={styles.testResults}>
                    <span className={`${styles.testResultsIcon} ${styles.iconSuccess}`} />
                    <Heading tag={'h3'} size={'md'}>
                      Performance test
                    </Heading>
                    <p className={styles.testResultsStatus}>Checkout API. 4 of 4 thresholds passed.</p>
                  </div>
                </div>
              </div>
              <div
                className={
                  'offset-xl-1 order-lg-last col-lg-5 col-12 order-first justify-content-center d-flex flex-column'
                }
              >
                <Heading className={`${styles.stepTitle}`}>5. Receive alerts on performance regressions</Heading>
                <p className={styles.stepDescription}>
                  Configure your notifications and get alerts if any change breaks your performance goals.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
