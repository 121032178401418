import classNames from 'classnames/bind';
import { Button } from 'components/shared/button';
import { Heading } from 'components/shared/heading';
import { HeroFrame } from 'components/shared/hero-frame';
import React from 'react';
import { docs } from 'utils/urls';

import ArrowSteep from './svg/arrow-steep.inline.svg';
import ArrowStraight from './svg/arrow-straight.inline.svg';
import styles from './unit-testing-for-performance-hero.module.scss';
import animation from './videos/unit-testing-for-performance.mp4';

const cx = classNames.bind(styles);
export const Hero = () => (
  <section className={styles.wrapper}>
    <div className={'container'}>
      <div className={'row'}>
        <div className={'col-lg-4'}>
          <Heading className={styles.title}>Like Unit Testing, for Performance</Heading>
          <p className={styles.description}>Pass/Fail behavior for easy automation and integration into CI pipelines</p>

          <div className={cx('buttonsWrapper', 'hide-md-down')}>
            {/* fdocs */}
            <Button
              className={styles.button}
              tag={'a'}
              href={`${docs}/testing-guides/automated-performance-testing/`}
              cursor
            >
              Learn more
            </Button>
          </div>
        </div>
        <div className={`col-lg-8 d-flex justify-content-center ${styles.imageCol}`}>
          <div
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              // eslint-disable-next-line max-len
              __html: `<video class="${styles.illustration}" src="${animation}" type="video/mp4" playsinline autoPlay loop muted />`, // Because React doesn't support attribute 'muted'. Nice, huh? https://github.com/facebook/react/issues/10389
            }}
          />
          <div className={cx('noteBlock', 'checks')}>
            <span className={styles.note}>
              Checks
              <ArrowStraight className={styles.arrow} />
            </span>
          </div>
          <div className={cx('noteBlock', 'load')}>
            <span className={styles.note}>
              Load
              <ArrowSteep className={styles.arrow} />
            </span>
          </div>
          <div className={cx('noteBlock', 'threshold')}>
            <span className={styles.note}>
              Threshold
              <ArrowStraight className={styles.arrow} />
            </span>
          </div>
        </div>

        <div className={'col-lg-12 show-md-down'}>
          <div className={styles.buttonsWrapper}>
            <Button className={styles.button} tag={'link'} to={'/cloud'} cursor>
              Start Testing
            </Button>
          </div>
        </div>
      </div>
    </div>

    <HeroFrame backgroundLabel={styles.frameWrapper} />
  </section>
);
