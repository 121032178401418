import { SEO } from 'components/blocks/seo';
import { Hero } from 'components/pages/unit-testing-for-performance/hero';
import { Instruction } from 'components/pages/unit-testing-for-performance/instruction';
import { DefaultLayout } from 'layouts/default-layout';
import React from 'react';
import SEO_DATA from 'utils/seo-metadata';

const UnitTesting = () => (
  <DefaultLayout>
    <Hero />
    <Instruction />
  </DefaultLayout>
);

export default UnitTesting;

export const Head = () => <SEO {...SEO_DATA['unit-testing-for-performance']} />;
